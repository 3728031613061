export const Reducer = (state, action) => {
    switch (action.payload) {
      case "de" || "DE":
        return { ...state, id: 1 };
      case "fr" || "FR":
        return { ...state, id: 2 };
      case "en" || "EN":
        return { ...state, id: 3 };
      case "it" || "IT":
        return { ...state, id: 4 };
      default:
        return { ...state, id: 0 };
    }
  };
  
  export default Reducer;