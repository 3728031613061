import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../images/logo_black.png";

import Footer from "./components/Footer";
import Loading from "./components/Loading";

import "../css/style.css";

const Step10_Shipping = () => {
    const [user, setUser] = useState({
        salutation: "",
        firstname: "",
        lastname: "",
        company: "",
        function: "",
        street: "",
        place: "",
        city: "",
        country: "176",
        phone: "",
        email: "",
        howHaveYouHeard: "",
        exhibitor: "Aussteller: false",
        logoPresence: "Ausstellerfläche / Logopräsenz: false",
        investment: "Investieren: false",
        cityTour: "Privaten Kunsthaus Führung: false",
        vrMandate: "",
        agreement: false,
        cancelationTerms: false,
        isCustomCheckboxChecked: false
    });

    const theEvent = JSON.parse(sessionStorage.getItem("FUWEvent"));
    const remarks = JSON.parse(theEvent.remarks);
    const howHaveYouHeard = remarks["ListItemsHowHaveYouHeard"];
    const [orderId] = useState(sessionStorage.getItem("OrderId"));

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(""); //Todo how can i handle these errors
    const [countries, setCountries] = useState([]);
    const [resources, setResources] = useState({});
    const [token] = useState(sessionStorage.getItem("token"));

    // fetching resources
    const { language } = useParams();

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestResources();
        requestCountries();

    }, [language]);

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestCountries = () => {
        setLoading(true);

        axios
            .get(`${baseUrl}/Countries?languageCode=${language}`)
            .then((res) => {
                let countries = res.data;

                //first add switzerland, germany and austria
                const indexCH = countries.findIndex((c) => c.id === 176);
                const ch = countries[indexCH];
                countries.splice(indexCH, 1);

                var indexDE = res.data.findIndex((c) => c.id === 229);
                const de = countries[indexDE];
                countries.splice(indexDE, 1);

                var indexAU = res.data.findIndex((c) => c.id === 228);
                const au = countries[indexAU];
                countries.splice(indexAU, 1);

                countries.unshift({ id: "", name: "---------" });
                countries.unshift(au);
                countries.unshift(de);
                countries.unshift(ch);

                setCountries(res.data);
            })
            .catch((error) => {
                console.error(error.response.data);
                navigate(`/${language}/sessiontimeout`);
            });

        setTimeout(() => setLoading(false), 500);
    };

    const onOptionsChange = (e) => {

        if (e.target.value.includes("Ausstellerfläche / Logopräsenz") || e.target.value.includes("logo presence")) {
            setUser({ ...user, ["logoPresence"]: "Ausstellerfläche / Logopräsenz: " + e.target.checked });
        }

        if (e.target.value.includes("Investieren") || e.target.value.includes("Invest in your knowledge")) {
            setUser({ ...user, ["investment"]: "Investieren: " + e.target.checked });
        }

        if (e.target.value.includes("Aussteller ")) {
            setUser({ ...user, ["exhibitor"]: "Aussteller: " + e.target.checked });
        }

        if (e.target.value.includes("Privaten Kunsthaus Führung")) {
            setUser({ ...user, ["cityTour"]: "Privaten Kunsthaus Führung: " + e.target.checked });
        }

        if (e.target.value.includes("agreement")) {
            setUser({ ...user, ["agreement"]: e.target.checked });
        }

        if (e.target.value.includes("cancelationTerms")) {
            setUser({ ...user, ["cancelationTerms"]: e.target.checked });
        }
    }

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const mapCheckboxOptions = () => {

        let options = remarks["Options"];

        return (
            options.map(
                (opt, index) => (
                    <div key={index} className="row mt-4">
                        <div className="col-md-12">
                            <div class="form-check">
                                <input className="form-check-input" id={"chkOption" + index} type="checkbox" value={opt} onChange={(e) => onOptionsChange(e)} />
                                <label className="form-check-label" htmlFor={"chkOption" + index}>{opt}</label>
                            </div>
                        </div>
                    </div>
                )
            )
        );
    }

    // validating input fields
    let validation =
        /^([a-z A-Zöéàäèü]{1,70})$/.test(user.salutation) &&
        /^.{1,70}$/.test(user.firstname) &&
        /^.{1,70}$/.test(user.lastname) &&
        /^.{1,70}$/.test(user.function) &&
        /^.{1,70}$/.test(user.company) &&
        /^.{1,70}$/.test(user.street) &&
        /^.{1,70}$/.test(user.place) &&
        /^.{1,70}$/.test(user.city) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.country) &&
        /^.{1,70}$/.test(user.phone) &&
        /^\S+@\S+\.\S+$/.test(user.email);

    if (validation && (remarks["HasAgreement"])) {
        validation = user.agreement;
    }

    if (validation && (remarks["HasCancelationTerms"])) {
        validation = user.cancelationTerms;
    }


    const addAddressToBasket = () => {
        let addressBody = {
            salutation: user.salutation,
            firstname: user.firstname,
            name: user.lastname,
            company: user.company,
            street: user.street,
            function: user.function,
            postCode: user.place,
            city: user.city,
            telephone: user.phone,
            countryId: user.country,
            email: user.email,
            code1: user.investment,
            code2: user.exhibitor,
            code3: user.logoPresence,
            code4: user.vrMandate,
            code5: user.howHaveYouHeard,
            code6: user.cityTour,
            code7: "Homepage checkbox: " + sessionStorage.getItem("isCustomCheckboxChecked")
        };

        axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then((res) => {
                sessionStorage.setItem("FUWOrderAddress", JSON.stringify(addressBody));
                navigate(`/${language}/participants`);
            })
            .catch((error) => {
                setErrors(error.response.data);
            });
    };

    return (
        <div>
            {loading ? (
                <>
                    <div className="d-none d-lg-block">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="50%"
                            left="50%"
                        />
                    </div>
                    <div className="d-lg-none">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="45%"
                            left="45%"
                        />
                    </div>
                </>
            ) : (
                <>
                    {resources.translation && (
                        <div className="container pt-0 wrapper">
                            <div className="row mt-2">
                                <div className="col-md-4 text-center">
                                    <img src={logo} className="img-fluid" alt="FUW Logo"></img>
                                </div>
                            </div>
                            <div className="row mt-5 text-center">
                                <div className={"col-md-4"}>
                                    <p className="navFUWActive">{resources.translation._Buyer}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className="navFUW">{resources.translation._Tickets}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className="navFUW">{remarks["WorkshopsTitle"]}</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12 text-center">
                                    <h2 className="text-uppercase underline50">{resources.translation._Buyer}</h2>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 mb-4 text-center">
                                    <span className="description">
                                        {resources.translation.ShippingPleaseFill}
                                    </span>
                                </div>
                            </div>

                            {/* user data */}

                            <div className="row">
                                <div className="col-md-12">
                                    <select
                                        className="form-control inputUserInfo"
                                        aria-label={resources.translation.ShippingSalutation}
                                        name="salutation"
                                        value={user.salutation}
                                        onChange={(e) => onInputChange(e)}
                                        required>
                                        <option>{resources.translation.ShippingSalutation + "*"}</option>
                                        <option value={resources.translation._Mr}>{resources.translation._Mr}</option>
                                        <option value={resources.translation._Mrs}>{resources.translation._Mrs}</option>
                                        {resources.translation._Ms && resources.translation._Ms != "" &&
                                            <option value={resources.translation._Ms}>{resources.translation._Ms}</option>
                                        }
                                    </select>

                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="firstname"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingFirstName + "*"}
                                        value={user.firstname}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="lastname"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingLastName + "*"}
                                        value={user.lastname}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="function"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingFunction + "*"}
                                        value={user.function}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="vrMandate"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingVrMandate}
                                        value={user.vrMandate}
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="company"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingCompany + "*"}
                                        value={user.company}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="street"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingStreet + "*"}
                                        value={user.street}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="place"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingPlace + "*"}
                                        value={user.place}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="city"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingCity + "*"}
                                        value={user.city}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <select
                                        className="form-control inputUserInfo"
                                        aria-label="Land"
                                        name="country"
                                        value={user.country}
                                        onChange={(e) => onInputChange(e)}>
                                        {countries.map((c, index) => (
                                            <option value={c.id} key={index}>
                                                {c.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        name="phone"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingPhone + "*"}
                                        value={user.phone}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control inputUserInfo"
                                        placeholder={resources.translation.ShippingEmail + "*"}
                                        value={user.email}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 pe-md-0 mt-4">
                                    <label className="form-control inputUserInfo">
                                        {resources.translation.ShippingWhereDidYouHear}
                                    </label>
                                </div>
                                <div className="col-md-8 ps-md-0 mt-4">
                                    <select
                                        className="form-control inputUserInfo"
                                        aria-label="Where did you hear"
                                        name="howHaveYouHeard"
                                        value={user.howHaveYouHeard}
                                        onChange={(e) => onInputChange(e)}>
                                        <option>{"-- " + resources.translation._PleaseChoose + " --"}</option>
                                        {howHaveYouHeard.map((h, index) => (
                                            <option value={h} key={index}>
                                                {h}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* checkbox options */}

                            {mapCheckboxOptions()}

                            {/* next button */}

                            <div className={"row mt-5"}>
                                <div className="offset-md-10 col-md-2 text-end">
                                    <button className="btn form-control btnDarkblue" onClick={addAddressToBasket} disabled={!validation}>
                                        {resources.translation._Next}
                                    </button>
                                </div>
                            </div>

                            {/* agb - cancelation terms */}

                            <div className="container">

                                {remarks["HasAgreement"] && (
                                    <div className="float-end position-relative mt-4">
                                        <div className="col-md-12 float-end">
                                            <label htmlFor="chkAgreement" className="me-3 lblAgreement" dangerouslySetInnerHTML={{ __html: resources.translation.ShippingAgreement.replace("{@pdfUrl}", remarks["AgreementPDF"]) }}></label>
                                            <input id="chkAgreement" type="checkbox" className="checkboxAgreement form-check-input" value="agreement" onChange={(e) => onOptionsChange(e)} />
                                        </div>
                                    </div>
                                )}

                                {remarks["HasCancelationTerms"] && (
                                    <div className="float-end position-relative mt-4">
                                        <div className="col-md-12 float-end">
                                            <label htmlFor="chkCancelationTerms" className="me-3 lblAgreement">{remarks["CancelationTermsText"]}</label>
                                            <input id="chkCancelationTerms" type="checkbox" className="checkboxAgreement form-check-input" value="cancelationTerms" onChange={(e) => onOptionsChange(e)} />
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    )}
                    <div className="container wrapper">
                        <div className="col-md-12 pt-4 text-center">
                            <Footer language={language} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Step10_Shipping;