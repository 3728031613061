import React from "react";

import image from "../../images/ticketino.png";
import styles from "../../index.module.css";

const SessionTimeOut = () => {
    return (
        <div className={styles.pageContainer}>
            <div className={styles.logoDiv}>
                <img className={styles.logo} src={image} alt="logo"></img>
            </div>
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="text-center">
                    <p className="text text-dark fw-light fs-4 mb-0">
                        Ihre Sitzung ist abgelaufen
                    </p>
                    <p className="text text-dark fw-light fs-6">
                        Bitte beginnen Sie erneut
                        <span className={styles.textNotFound}>.</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SessionTimeOut;
