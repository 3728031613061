import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import logo from "../images/logo_black.png";

import Footer from "./components/Footer";

import "../css/style.css";

const Step50_Confirmation = () => {

    const theEvent = JSON.parse(sessionStorage.getItem("FUWEvent"));
    const remarks = JSON.parse(theEvent.remarks);

    const [resources, setResources] = useState({});
    const [orderId] = useState(sessionStorage.getItem("OrderId"));

    // fetching resources
    const { language } = useParams();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        loadToken();
    }, [language]);

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
          axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
    
          requestResources();
        });
      };

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
                confirmOrder();
            })
            .catch((error) => console.log(error.response.data));
    };

    // confirm the order
    const confirmOrder = () => {
        axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`).catch((error) => console.error(error.response.data));
    };

    return (
        <div>

            {resources.translation && (
                <div className="container pt-0 wrapper">
                    <div className="row mt-2">
                        <div className="col-md-4 text-center">
                            <img src={logo} className="img-fluid" alt="FUW Logo"></img>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12 mt-5">
                            <div dangerouslySetInnerHTML={{ __html: remarks["ConfirmationText"] }} className="description"></div>
                        </div>
                    </div>
                </div>
            )}

            <div className="container wrapper">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <Footer language={language} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step50_Confirmation;