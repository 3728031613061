import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../images/logo_black.png";

import Footer from "./components/Footer";
import Loading from "./components/Loading";

import "../css/style.css";

const Step20_Participants = () => {

    const theEvent = JSON.parse(sessionStorage.getItem("FUWEvent"));
    const remarks = JSON.parse(theEvent.remarks);
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [order, setOrder] = useState({});
    const orderAddress = JSON.parse(sessionStorage.getItem("FUWOrderAddress"));
    const [bookedTickets, setBookedTickets] = useState([]);
    const [ticketTypeInfos, setTicketTypeInfos] = useState([]);

    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState({});
    const [token] = useState(sessionStorage.getItem("token"));

    // fetching resources
    const { language } = useParams();

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        requestResources();

    }, [language]);

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestOrderInformation();

    }, []);

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestOrderInformation = () => {
        setLoading(true);

        axios
            .get(`${baseUrl}/ShopBasket/Order/${orderId}`)
            .then((res) => {
                setBookedTickets(res.data.tickets);
                setOrder(res.data);
                res.data.tickets.map((t) => requestTicketTypeInfo(t.ticketTypeId));

                setTimeout(() => setLoading(false), 500);
            })
            .catch((error) => {
                console.error(error.response.data);
                navigate(`/${language}/sessiontimeout`);
            });
    };

    const requestTicketTypeInfo = (ticketTypeId) => {
        axios
            .get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`)
            .then((res) => {
                setTicketTypeInfos((current) => [...current, res.data.ticketTypeInfos[0]]);
            })
            .catch((error) => console.log(error.response.data));
    };

    const onClickFillUserData = (ticketId) => {

        bookedTickets.forEach(t => {

            if (t.id === ticketId) {
                t.salutation = orderAddress.salutation;
                t.firstname = orderAddress.firstname;
                t.lastname = orderAddress.name;
                t.function = orderAddress.function;
                t.option4 = orderAddress.code4;
                t.email = orderAddress.email;
                t.company = orderAddress.company;
            }
        });

        setBookedTickets([...bookedTickets]);
    };

    const onInputChange = (e, ticketId) => {

        bookedTickets.forEach(t => {
            if (t.id === ticketId) {
                t[e.target.name] = e.target.value;
            }
        });

        setBookedTickets([...bookedTickets]);
    };

    // validating input fields
    let validatorTicketInputs = 0;

    bookedTickets.forEach(t => {
        if (!(/^([a-z A-Z������]{1,70})$/.test(t.salutation) &&
            /^.{1,70}$/.test(t.firstname) &&
            /^.{1,70}$/.test(t.lastname) &&
            /^.{1,70}$/.test(t.function) &&
            /^.{1,70}$/.test(t.company) &&
            /^\S+@\S+\.\S+$/.test(t.email))) {
            validatorTicketInputs += 1;
        }
    });

    const mapTicketsUserData = () => {

        return (
            ticketTypeInfos &&
            ticketTypeInfos.length > 0 &&
            bookedTickets &&
            bookedTickets.length > 0 &&
            bookedTickets.map(
                (bt, index) => (
                    <div key={bt.id} className="mb-5">
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <h5 className="text-uppercase">Ticket: {ticketTypeInfos.find(tti => tti.ticketTypeId === bt.ticketTypeId).name}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <small className="fillUserData" onClick={() => onClickFillUserData(bt.id)}>{resources.translation.ParticipantsGetBuyerInput}</small>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <select
                                    className="form-control inputUserInfo"
                                    aria-label={resources.translation.ShippingSalutation}
                                    name="salutation"
                                    value={bt.salutation}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required>
                                    <option>{resources.translation.ShippingSalutation + "*"}</option>
                                    <option value={resources.translation._Mr}>{resources.translation._Mr}</option>
                                    <option value={resources.translation._Mrs}>{resources.translation._Mrs}</option>
                                </select>

                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="firstname"
                                    className="form-control inputUserInfo"
                                    placeholder={resources.translation.ShippingFirstName + "*"}
                                    value={bt.firstname}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="lastname"
                                    className="form-control inputUserInfo"
                                    placeholder={resources.translation.ShippingLastName + "*"}
                                    value={bt.lastname}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="function"
                                    className="form-control inputUserInfo"
                                    placeholder={resources.translation.ShippingFunction + "*"}
                                    value={bt.function}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="option4"
                                    className="form-control inputUserInfo"
                                    placeholder={resources.translation.ShippingVrMandate}
                                    value={bt.option4}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    name="company"
                                    className="form-control inputUserInfo"
                                    placeholder={resources.translation.ShippingCompany + "*"}
                                    value={bt.company}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control inputUserInfo"
                                    placeholder={resources.translation.ShippingEmail + "*"}
                                    value={bt.email}
                                    onChange={(e) => onInputChange(e, bt.id)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                )
            )
        );
    }

    const addDataToTickets = () => {

        //update ticket address
        bookedTickets.forEach((t, key, bookedTickets) => {
            let ticketBody = {
                salutation: t.salutation,
                firstname: t.firstname,
                name: t.lastname,
                function: t.function,
                company: t.company,
                option4: t.option4,
                email: t.email
            };

            axios
                .put(`${baseUrl}/Ticket/${t.id}/Address`, ticketBody)
                .then(() => {
                    if (Object.is(bookedTickets.length - 1, key)) {
                        /* redirect to workshops or checkout page on last interation */
                        navigareToWorkshopsOrCheckoutPages();
                    }
                })
                .catch((error) => console.log(error.response.data));
        });
    }

    const navigareToWorkshopsOrCheckoutPages = () => {
        //check if booked ticket are workshop ticket types
        let workshopTicketTypeIds = remarks["WorkshopTicketTypeIds"];

        //Todo is there a better way to map this
        let validator = 0;
        for (var i = 0; i < bookedTickets.length; i++) {

            for (var j = 0; j < workshopTicketTypeIds.length; j++) {
                if (bookedTickets[i].ticketTypeId === workshopTicketTypeIds[j]) {
                    validator += 1;
                }
            }
        }

        //if there isn't a workshop navigate to checkout or confirmation page
        if (validator > 0) {
            navigate(`/${language}/workshops`);
        } else {
            if (order.totalPrice === 0) {
                changePaymentTypeToFree();
            } else {
                navigate(`/${language}/checkout`);
            }
        }
    }

    const changePaymentTypeToFree = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(() => {
                navigate(`/${language}/confirmation`);
            });
    };

    return (
        <div>
            {loading ? (
                 <>
                    <div className="d-none d-lg-block">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="50%"
                            left="50%"
                        />
                    </div>
                    <div className="d-lg-none">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="45%"
                            left="45%"
                        />
                    </div>
                </>
            ) : (
                <>
                    {resources.translation && (
                        <div className="container pt-0 wrapper">
                            <div className="row mt-2">
                                <div className="col-md-4 text-center">
                                    <img src={logo} className="img-fluid" alt="FUW Logo"></img>
                                </div>
                            </div>
                            <div className="row mt-5 text-center">
                                <div className={"col-md-4"}>
                                    <p className="navFUW">{resources.translation._Buyer}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className="navFUWActive">{resources.translation._Tickets}</p>
                                </div>
                                <div className={"col-md-4"}>
                                    <p className="navFUW">{remarks["WorkshopsTitle"]}</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12 text-center">
                                    <h2 className="text-uppercase underline50">{resources.translation._Tickets}</h2>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 mb-4 text-center">
                                    <span className="description">
                                        {resources.translation.ParticipantsPleaseFill}
                                    </span>
                                </div>
                            </div>

                            {/* user data */}

                            {mapTicketsUserData()}

                            {/* next button */}

                            <div className={"row mt-5"}>
                                <div className="offset-md-10 col-md-2 text-end">
                                    <button className="btn form-control btnDarkblue" onClick={addDataToTickets} disabled={validatorTicketInputs > 0}>
                                        {resources.translation._Next}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="container wrapper">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Footer language={language} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Step20_Participants;