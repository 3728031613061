import axios from "axios";
import React, { useEffect, useState } from "react";

const Footer = (props) => {
  const [resources, setResources] = useState({});

  useEffect(() => {
    requestResources();
  }, [props.language]);

  const requestResources = () => {
    axios.get(`form/resources/${props.language}`).then((res) => {
      setResources(res.data);
    });
  };

  return (
      <p className="mt-5">
          {resources.translation && (
              <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
              )}
      </p>
    );
};

export default Footer;
