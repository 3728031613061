import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// SwissClassicalWorld
import Home from "./views/Home";
import Shipping from "./views/Step10_Shipping";
import Participants from "./views/Step20_Participants";
import Workshops from "./views/Step30_Workshops";
import Checkout from "./views/Step40_Checkout";
import Confirmation from "./views/Step50_Confirmation";

// Fallback page
import PageNotFound from "./views/components/PageNotFound";
import SessionTimeOut from "./views/components/SessionTimeOut";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route path="/:language/sessiontimeout" element={<SessionTimeOut />} />

                <Route path="/:language/home/:eventId" element={<Home />} />
                <Route path="/:language/shipping" element={<Shipping />} />
                <Route path="/:language/participants" element={<Participants />} />
                <Route path="/:language/workshops" element={<Workshops />} />
                <Route path="/:language/checkout" element={<Checkout />} />
                <Route path="/:language/confirmation" element={<Confirmation />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
